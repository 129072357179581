<template>
  <div>
    <div class="search">
      <div>
        <div class="a-inline-block">
          <label for="">关键字</label>
          <a-input v-model="key" size="large" placeholder="请输入关键字" />
        </div>
        <div class="a-inline-block">
          <label for="">分类</label>
          <a-select v-model="type" class="ant-select-lg">
            <a-select-option :value="item.id" v-for="(item,index) in typeList" :key="index">{{item.name}}
            </a-select-option>
          </a-select>
        </div>
        <div class="a-inline-block">
          <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
          <a-button size="large" @click="reset">重置</a-button>
        </div>
      </div>
      <div>
        <a-button class="addBtn" size="large" type="primary" @click="addType">添加产品/服务</a-button>
        <a-button class="addBtn" size="large" type="primary" @click="delAll">批量删除</a-button>
      </div>

    </div>
    <a-table :row-selection="rowSelection" :rowKey="record=> record.id" :columns="columns" :data-source="data"
      id="table" class="table" :pagination="pagination">
      <span slot="num" slot-scope="text, record, index">
        {{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}
      </span>
      <template slot="Img" slot-scope="record">
        <img :src="record?ajaxUrl+'/scrm/index/getFile?id='+record:''" alt="" width="80px">
        <!-- <a-switch checked-children="启用" un-checked-children="禁用" :loading="switchLoading==record.id?true:false" :checked="record.status==1?true:false" @click="onChange(record)"/> -->
        <!-- <a class="action" href="javascript:;" @click="editTask(record.id)">编辑</a> -->
      </template>
      <template slot="Status" slot-scope="record">
        <a-switch checked-children="启" un-checked-children="禁" :loading="switchLoading==record.id?true:false"
          :checked="record.status==1?true:false" @click="onChange(record)" />
        <!-- <a class="action" href="javascript:;" @click="editTask(record.id)">编辑</a> -->
      </template>
      <template slot="Recommend" slot-scope="record">
        <a-switch checked-children="是" un-checked-children="否" :loading="switchLoading==record.id?true:false"
          :checked="record.recommend==2?true:false" @click="onChangeRecommend(record)" />
        <!-- <a class="action" href="javascript:;" @click="editTask(record.id)">编辑</a> -->
      </template>
      <template slot="Action" slot-scope="text,record">
        <a class="action" href="javascript:;" @click="editTask(record.id)">编辑</a>
        <!-- <a-popconfirm title="确定要关闭吗?" @confirm="() => off(record.id)">
                    <a class="action del" href="javascript:;">关闭</a>
                </a-popconfirm>  -->
        <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
          <a class="action del" href="javascript:;">删除</a>
        </a-popconfirm>
      </template>
    </a-table>
    <div style="height:100px"></div>
  </div>
</template>
<script>
// import $ from "jquery";
import { requestXml, getDateTime, ajaxUrl } from "../../../assets/js/request";
export default {
  data() {
    return {
      key: "",
      type: null,
      status: null,
      typeList: [],
      switchLoading: "",
      ajaxUrl: ajaxUrl,
      columns: [
        {
          title: "序号",
          dataIndex: "num",
          width: 70,
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        // { title: '商品编号', dataIndex: 'id',align:'center', key: '1'},
        { title: "商品分类", dataIndex: "pcname", align: "center", key: "2" },
        { title: "商品名称", dataIndex: "name", align: "center", key: "2" },
        {
          title: "图片",
          dataIndex: "img",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "Img" },
        },
        { title: "单价", dataIndex: "unitprice", align: "center", key: "5" },
        { title: "销售数量", dataIndex: "salenum", align: "center", key: "6" },
        { title: "添加时间", dataIndex: "created", align: "center", key: "7" },
        {
          title: "状态",
          key: "status",
          align: "center",
          scopedSlots: { customRender: "Status" },
        },
        {
          title: "首页推荐",
          key: "recommend",
          align: "center",
          scopedSlots: { customRender: "Recommend" },
        },
        {
          title: "操作",
          key: "operation",
          align: "center",
          // fixed: 'right',
          width: 200,
          scopedSlots: { customRender: "Action" },
        },
      ],
      data: [],

      // 分页
      pagination: {
        current: 1,
        total: 100,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showQuickJumper: true,
        showTotal: total => `共 ${total} 条`, // 显示总数
        onChange: (current) => this.changePage(current), //点击当前页码
        onShowSizeChange: (current, pageSize) => {
          //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
          console.log(pageSize);
          this.showSizeChange(current, pageSize);
        },
      },
      selectedRows: "",
      rowSelection: {
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
          this.selectedRows = selectedRows;
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
          this.selectedRows = selectedRows;
        },
      },
    };
  },
  mounted: function () {
    this.getType();
    this.getData();
  },
  methods: {
    // 搜索
    onSearch() {
      this.pagination.current=1;
      this.getData(this.key, this.type);
    },
    reset() {
      this.key = "";
      this.type = null;
      this.status = null;
      this.getData();
    },
    getType() {
      requestXml("/jieb/Product/typeshow", "POST", (res) => {
        this.typeList = res;
      });
    },
    getData() {
      requestXml(
        "/jieb/Product/showproduct",
        "POST",
        (res) => {
          for (let i = 0; i < res.list.length; i++) {
            res.list[i].created = getDateTime(res.list[i].created);
          }
          this.pagination.total = Number(res.page.total);
          this.pagination.pageSize = Number(res.page.pagesize);
          this.data = res.list;
        },
        {
          search: this.key,
          type: this.type,
          page: {
            curpage: this.pagination.current,
            pagesize: this.pagination.pageSize,
          },
        }
      );
    },

    // 分页
    changePage(current) {
      //current参数表示是点击当前的页码，
      // console.log(current)
      this.pagination.current = current;
      this.getData(); //向后端发送请求
    },
    showSizeChange(current, pageSize) {
      // console.log(current, pageSize)
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getData(); //向后端发送请求
    },
    // 产品禁用启用
    onChange(data) {
      let id = data.id;
      let status = data.status; //1启用 2禁用
      this.switchLoading = id;
      if (status == 1) {
        status = 2;
      } else if (status == 2) {
        status = 1;
      }
      requestXml(
        "/jieb/Product/saveStatus",
        "POST",
        (res) => {
          console.log(res);
          this.switchLoading = "";
          this.getData();
        },
        { id: id, status: status }
      );
    },
    // 设置首页退
    onChangeRecommend(data) {
      let id = data.id;
      let recommend = data.recommend; //1启用 2禁用
      this.switchLoading = id;
      if (recommend == 1) {
        recommend = 2;
      } else if (recommend == 2) {
        recommend = 1;
      }
      requestXml(
        "/jieb/Product/recommend",
        "POST",
        (res) => {
          console.log(res);
          this.switchLoading = "";
          this.getData();
        },
        { id: id, recommend: recommend }
      );
    },
    //添加产品
    addType() {
      this.$router.push({
        path: "/scrm_pc/set/goodsAdd",
      });
    },
    // 编辑
    editTask(id) {
      this.$router.push({
        path: "/scrm_pc/set/goodsAdd",
        query: {
          id: id,
        },
      });
    },
    // 删除
    onDelete(id) {
      requestXml(
        "/jieb/Product/delproduct",
        "POST",
        (res) => {
          console.log(res);
          this.getData();
        },
        { id: id }
      );
    },
     delAll() {
      if (this.selectedRows.length < 1) {
        this.$message.info("请先选择");
        return false;
      } else {
        let idArr = [];
        let sel = this.selectedRows;
        for (let i = 0; i < sel.length; i++) {
          idArr.push(sel[i].id);
        }
        this.onDelete(idArr);
      }
    },
  },
};
</script>
<style scoped>
.search {
  display: flex;
  justify-content: space-between;
}
.addBtn {
  width: auto;
}
.action {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 5px;
  background: #dceeff;
  border: 1px solid #36a3ff;
  color: #36a3ff;
  border-radius: 5px;
}
.del {
  background: #ffdcdc;
  border-color: #ff4c4c;
  color: #ff4c4c;
}
</style>

